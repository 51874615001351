import axios from 'axios';

const API_KEY =  'AIzaSyAnQNKd8br_3Ax_eekJq_4DtFezz20-e_o';

export default axios.create({
    baseURL: 'https://www.googleapis.com/youtube/v3',
params: {
    part: 'snippet',
    maxResults: 5,
    key: API_KEY
}
})