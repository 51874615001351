import React from 'react';

const VideoDetail = ({ video }) => {
    if(!video)
    {
        return (

        <div style={{marginTop:'30px'}} className="ui active inverted dimmer">
<div className="ui text loader">Videos Loading</div>
            </div>


            )
    }
const videoSrc = `https://youtube.com/embed/${video.id.videoId}`;

    return (
        <div>
            <div className="ui embed">
                <iframe title="video player" src={videoSrc} />
            </div>
            <div className="ui segment">
           <h4 className="ui header">{video.snippet.title}</h4>
           <p>{video.snippet.description}</p>
            </div>
            </div>
    )
}

export default VideoDetail;