import React, { useState, useEffect } from 'react';
import SearchBar from './SearchBar';
import VideoList from '../components/VideoList';
import VideoDetail from './VideoDetail';
import useVideos from '../hooks/useVideos';

const App = () => {

const [selectedVideo, setSeletedVideo] = useState(null);
    const[videos, search] = useVideos('surfing');


useEffect(() => {

setSeletedVideo(videos[0]);
}, [videos]);



    return (
        <div style={{marginTop:"10px"}} className="ui container"><SearchBar  onFormSubmit={search}/>

        <div className="ui grid">
        <div className="ui row">
            <div className="eleven wide column">
<VideoDetail video={selectedVideo} />
</div>
<div  className="five wide column">
<VideoList onVideoSelect={(video) => setSeletedVideo(video)} videos={videos}/>
</div>
        </div>
        </div>
        </div>
    )

}



/*class App extends React.Component {
    state = { videos: [], selectedVideo: null}
componentDidMount() {

}

   onTermSubmit = async (term) => {
      const response = await youtube.get('/search', {
           params: {
               q: term
           }
       });
       this.setState({
           videos: response.data.items,
           selectedVideo: response.data.items[0]
        });

   }
   onVideoSelect = video => {
       this.setState({ selectedVideo: video });

   }
    render () {
        return (
            <div style={{marginTop:"10px"}} className="ui container"><SearchBar  formSubmit={this.onTermSubmit}/>

            <div className="ui grid">
            <div className="ui row">
                <div className="eleven wide column">
<VideoDetail video={this.state.selectedVideo} />
</div>
<div  className="five wide column">
<VideoList onVideoSelect={this.onVideoSelect} videos={this.state.videos}/>
</div>
            </div>
            </div>
            </div>
        )
    }
}*/
export default App;